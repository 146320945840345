<template>
  <b-card>
    <b-row>
      <b-col v-if="role === 'consultant'" sm="8" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-process-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Process') }}
        </b-button>
      </b-col>
      <b-col sm="4">
        <b-form-group>
          <b-form-input
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            v-model="searchQuery"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refProcessListTable"
          :items="processes"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(controlsData)="data">
            <ol>
              <li
                v-for="(control, idx) in data.item.controlsData"
                :key="idx"
              >
                {{ control.name }}
              </li>
            </ol>
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <NavigationButton
                @clicked="router.push({ name:'habit-confirmation-new', params: { id: data.item._id } })"
                i18nHoverVariable="Evaluate"
              />
              <ViewButton variant="info" @clicked="router.push({name:'organization-process-view', params: { id: data.item._id } })" />
              <span v-if="role === 'consultant'">
                <EditButton @clicked="router.push({name:'organization-process-edit', params: { id: data.item._id } })" />
                <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
                <b-modal
                  :id="`modal ${data.item._id}`"
                  centered
                  no-close-on-backdrop
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')"
                  :ok-title="$t('message.delete')"
                  :cancel-title="$t('message.cancel')"
                  @ok="deleteProcess(data.item._id)"
                >
                  {{ $t('message.confirm_delete_process') }}
                </b-modal>
              </span>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12" class="d-flex justify-content-between flex-wrap">
        <div class="mb-1">
          <b-form-group class="mb-0" >
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="perPageOptions"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalProcesses"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import { ref, onMounted, onUnmounted, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import useProcessList from './useProcessList'

export default {
  components: {
    BTable,
    BPagination,
    EditButton,
    ViewButton,
    DeleteButton,
    NavigationButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { role } = store.state?.userStore?.userData
    const { router } = useRouter()

    const {
      fetchProcesses,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      totalProcesses,
      showingMessage,
      searchQuery,
      sortBy,
      isSortDirDesc,
      processes,
      deleteProcess,
      isLoading,
    } = useProcessList()

    onMounted(() => {
      fetchProcesses()
    })
    
    return {
      fetchProcesses,
      processes,
      showingMessage,
      router,
      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      role,
      deleteProcess,
      totalProcesses,
      currentPage,
      perPageOptions,
      perPage,
      isLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
